// extracted by mini-css-extract-plugin
export const post = "TwitterPost-module--post--1_i_p";
export const post__head = "TwitterPost-module--post__head--22v4O";
export const post__logo = "TwitterPost-module--post__logo--2TB_S";
export const post__avatar = "TwitterPost-module--post__avatar--3j02K";
export const post__names = "TwitterPost-module--post__names--3Pg-d";
export const post__login = "TwitterPost-module--post__login--3q82h";
export const post__name = "TwitterPost-module--post__name--3Ydci";
export const message = "TwitterPost-module--message--3cuvA";
export const media = "TwitterPost-module--media--39j-A";
export const additional = "TwitterPost-module--additional--1nxX6";
export const additional__likes = "TwitterPost-module--additional__likes--1oT5f";
export const additional__comments = "TwitterPost-module--additional__comments--UGzsm";
export const additional__time = "TwitterPost-module--additional__time--2ThLQ";
export const additional__img = "TwitterPost-module--additional__img--1ewNH";
export const additional__text = "TwitterPost-module--additional__text--s28EA";
export const post_big = "TwitterPost-module--post_big--1kS1X";
export const mobile__hidden = "TwitterPost-module--mobile__hidden--3_E4A";